@import '../../UI/Style/variable.scss';
@import '../../../node_modules/bootstrap/scss/_functions';
@import '../../../node_modules/bootstrap/scss/_variables';
@import '../../../node_modules/bootstrap/scss/mixins';

.video-feedback {
  background-color: $brand-background-color;
  padding: 50px 0 50px 50px;
  display: flex;
  border-radius: 30px;
  max-width: 1225px;
  gap: 20px;
  @include media-breakpoint-up(md) {
    flex-direction: column;
  }
  .left-part {
    flex-basis: 45%;
    .heading {
      h2 {
        color: $brand-color-white;
        font-size: 28px;
        line-height: 35px;
        font-family: $font-Roboto-Bold;
      }
    }
    .description {
      p {
        color: $brand-color-white;
        font-size: 20px;
        line-height: 25px;
        font-family: $font-Roboto-Regular;
      }
    }
  }
  .right-part {
    position: relative;
    .feeback-video-image {
      height: 370px;
      position: absolute;
      width: 720px;
    }
    .videoPlay-icon {
      position: absolute;
      transform: translate(208%, -50%);
      top: 50%;
    }
  }
}
