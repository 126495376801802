@import '../../UI/Style/variable';
@import '../../../node_modules/bootstrap/scss/_functions';
@import '../../../node_modules/bootstrap/scss/_variables';
@import '../../../node_modules/bootstrap/scss/mixins';

.FloatingWidget {
  position: fixed;
  bottom: 20px;
  right: 0px;
  background-color: $brand-color-white;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  //max-width: 400px;
  //min-width: 350px;
  z-index: 3;
  transition: 1s ease-out;
  @media only screen and (min-width: 360px) and (max-width: 370px) {
    right: -90%;
   //bottom: -40%;
    z-index: 9;
    min-width: 100%;
    max-width: 100%;
  }
  @media only screen and (min-width: 375px) and (max-width: 379px) {
    right: -90%;
    //bottom: -40%;
    z-index: 9;
    min-width: 100%;
    max-width: 100%;
  }
  @media only screen and (min-width: 380px) and (max-width: 399px) {
    right: -90%;
    //bottom: -25%;
    z-index: 9;
    min-width: 100%;
    max-width: 100%;
  }
  @media only screen and (min-width: 412px) and (max-width: 429px) {
    right: -90%;
    //bottom: -25%;
    z-index: 9;
    min-width: 100%;
    max-width: 100%;
  }
  @media only screen and (min-width: 430px) and (max-width: 450px) {
    right: -90%;
   // bottom: -20%;
    z-index: 9;
    min-width: 100%;
    max-width: 100%;
  }

  .banner-image {
    position: relative;
    .float-band {
      cursor: pointer;
      background-color: $brand-background-color;
      padding: 15px;
      max-width: 50px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      writing-mode: vertical-lr;
      text-align: center;
      transform: rotate(180deg);
      // max-height: 280px;
      //position: absolute;
      left: -12.5%;
      @include media-breakpoint-down(sm) {
        padding: 10px;
      }
      &-text {
        color: $brand-color-white;
        text-orientation: mixed;
        font-size: 18px;
        line-height: 22px;
        font-family: $font-Roboto-Regular;
        @include media-breakpoint-down(sm) {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
    .close-btn {
      position: absolute;
      right: -5px;
      background: transparent;
      border: 0;
      font-size: 30px;
    }
  }

  .description {
    background-color: $brand-background-color;
    padding: 10px;
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        color: $brand-color-white;
        font-size: 10px;
        line-height: 12px;
        font-family: $font-Roboto-Regular;
      }
    }
  }
  .health-card-form {
    background-color: $brand-light-shade-bg;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    input {
      height: 35px;
      border-radius: 8px;
    }
    .primary-btn {
      max-width: 135px;
      height: 35px;
    }
  }
  // &.mob-float {
  //   right: -27%;
  //   @include media-breakpoint-down(sm) {
  //     right: 0%;
  //     bottom: 0;
  //   }
  //   @media screen and (min-width: 1920px) {
  //     right: -21%;
  //   }
  //   @media only screen and (min-width: 390px) and (max-width: 500px) {
  //     bottom: 50px;
  //   }
  //   @media only screen and (min-width: 767px) and (max-width: 999px) {
  //     right: 0;
  //     bottom: 0;
  //   }
  //   //   padding: 20px;
  //   //   position: absolute;
  //   //   top: 200px;
  //   //   width: 95%;
  //   //   right: 2%;
  //   //   height: 616px;
  //   //   background-color: rgba(155, 10, 11, 0.3);
  //   //   border: 1px solid rgba(155, 10, 11, 0.3);
  //   //   box-shadow: 0.2px 0.6px 0.5px 0.5px;
  //   //   z-index: 1;
  //   &::before {
  //     @include media-breakpoint-down(sm) {
  //       position: absolute;
  //       top: -180px;
  //       left: 0;
  //       right: 0;
  //       bottom: 0;
  //       background: rgba(155, 10, 11, 0.3);
  //       content: '';
  //       z-index: -1;
  //       height: 115vh;
  //     }
  //   }
  // }
  .common-modal {
    .modal-dialog {
      @include media-breakpoint-up(lg) {
        max-width: 550px;
      }
      @include media-breakpoint-down(sm) {
        top:20%;
      }
      .modal-content {
        .modal-header {
          position: absolute;
          z-index: 1;
          right: 0;
          border: 0;
          padding: 0;
        }
      }
    }
  }
}

// @keyframes slideInFromRight {
//   0% {
//     transform: translateX(0);
//   }

//   100% {
//     transform: translateX(-20%);
//   }
// }
