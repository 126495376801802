@import "../../UI/Style/variable.scss";
@import "../../../node_modules/bootstrap/scss/_functions";
@import "../../../node_modules/bootstrap/scss/_variables";
@import "../../../node_modules/bootstrap/scss/mixins";
.custom-card {
  background: $brand-color-white;
  max-width: 286px;
  min-width: 286px;
  max-height: 431px;
  border-radius: 16px;
  box-shadow: 0px 0px 10px 0px rgb(71 71 71 / 30%);
  
  @include media-breakpoint-down(md) {
    max-width: 200px;
    min-width: 200px;
  }
  @media only screen and (min-width:1280px) and (max-width:1499px){
    max-width: 240px;
    min-width: 240px;
  }
  @media only screen and (min-width:992px) and (max-width:1279px){
    max-width: 220px;
    min-width: 220px;
  }

  &:hover {
    box-shadow: 0px 0px 10px 0px rgb(71 71 71 / 90%);
    transition: 0.5s;
  }

  .img-wrapper {
    //padding:10px;
    text-align: center;
    max-width: 292px;
    max-height: 185px;
    img{
      //width: 292px;
      height: 185px;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      @media only screen and (min-width:992px) and (max-width:1300px){
        width: 225px;
        
      }
    }
    @include media-breakpoint-down(md) {
      //padding-top:10px;
      img {
       // width: 100px;
      }
    }
  }

  .content-wrapper {
    padding: 15px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include media-breakpoint-down(md) {
      padding: 15px;
    }
    .heading {
      color: $brand-background-color;
      //font-family: Roboto;
      font-size: 18px;
      font-weight: 600;
      min-height: 60px;
      margin: 0;
      line-height: 24px;
      font-family: $font-Roboto-Regular;
      @include media-breakpoint-down(md) {
        font-size: 16px;
        line-height: 20px;
        min-height: initial;
        min-height: 40px;
      }
    }

    p {
      color: $brand-light-dark-shade;
      font-size: 14px;
      font-weight: 300;
      min-height: 36px;
      max-height: 36px;
      line-height: 18px;
      font-family: $font-Roboto-Regular;
      text-transform: capitalize;
      @include media-breakpoint-down(lg) {
        margin: 15px 0 0;
        font-size: 14px;
        line-height: 20px;
        // min-height: initial;
        // max-height: initial;
      }
    }

    .buttonText {
      color: $brand-text-color;
      font-size: 14px;
      font-weight: 400;
      font-family: $font-Roboto-Regular;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      text-align: left;
      padding:0;
      color:$brand-light-dark-shade;
      @include media-breakpoint-down(md) {
        margin: 15px 0 0;
        
      }
      svg {
        margin-left: 10px;
        vertical-align: middle;
        color: $brand-light-dark-shade;
      }
      &:hover {
        svg {
          transform: translateX(10px);
          transition: 0.5s ease-out;
        }
      }
    }
    .readMore-btn {
      color: $brand-text-color;
      font-family: $font-Roboto-Regular;
      font-size: 24px;
      line-height: 20px;
      min-height: 50px;
      width: 100%;
      font-style: normal;
      font-weight: 500;
      background-color: $brand-color-white;
      border: none;
      outline: none;
      cursor: pointer;
      
      @include media-breakpoint-down(md) {
        font-size: 18px;
        min-height: 40px;
      }
      box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
      &:hover {
        box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.5);
      }
    }
  }
}
