@import "../../../UI/Style/variable.scss";
@import "../../../../node_modules/bootstrap/scss/_functions";
@import "../../../../node_modules/bootstrap/scss/_variables";
@import "../../../../node_modules/bootstrap/scss/mixins";
@import "../../../../node_modules//bootstrap/scss/modal";

.common-modal {
  //max-width: 1000px;
  backdrop-filter: brightness(0.5);
  &.show {
    display: block;
  }
  .modal-dialog {
    max-width: 1000px;
    width: 650px;
    @include media-breakpoint-down(sm) {
      width: 100%;
      margin: 0;
    }
    .modal-header {
      justify-content: space-between;
      img {
        width: 100px;
        @include media-breakpoint-down(lg) {
          width: 50px;
        }
      }
      .close {
        outline: none;
        border: none;
        width: 40px;
        height: 40px;
        background: none;
        &::before {
          content: url("../../../assets/Images/Svg/xmark-solid.svg");
        }
      }
    }
    .modal-body {
      .content-wrapper {
        .heading {
          margin: 20px 0 30px;
          span {
            font-size: 30px;
            line-height: 50px;
            color: $brand-background-color;
            font-weight: 700;
            font-family: $font-Roboto-Regular;
            @include media-breakpoint-down(sm) {
              font-size: 25px;
              line-height: 40px;
            }
          }
        }
        .description {
          span {
            font-size: 20px;
            line-height: 24px;
            color: $brand-text-color;
            font-weight: 500;
            font-family: $font-Roboto-Regular;
          }
        }
        .phone-numbers {
          margin: 20px 0;

          span,a {
            font-size: 25px;
            line-height: 30px;
            font-family: $font-Roboto-Regular;
            color: $brand-text-color;
            font-weight: 500;
            display: block;
            padding: 15px;
            text-decoration: none;
          }
        }
      }
      .btn-section{
        margin:15px auto;
      }
      .otp-form{
        text-align: left;
        .lbl-text{
          font-size: 18px;
          line-height: 20px;
          font-weight: 600;
          font-family: $font-Roboto-Regular;
          align-self: center;
        }
        .custom-button{
          height: 35px;
          min-width: 100px;
        }
      }
      
    }
    .modal-footer {
      justify-content: center;
      .custom-primary-btn {
        width: 150px;
        height: 48px;
        background-color: $brand-text-color;
        color: $brand-color-white;
        font-size: 18px;
        line-height: 20px;
        font-family: $font-Roboto-Regular;
        font-weight: 500;
        border: none;
        outline: none;
        &:hover {
          box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.7);
          background-color: $brand-background-color;
        }
      }
    }
  }
}
