@import "../UI/Style/variable.scss";
@import "../../node_modules/bootstrap/scss/_functions";
@import "../../node_modules/bootstrap/scss/_variables";
@import "../../node_modules/bootstrap/scss/mixins";
.footer {
  background-color: $brand-background-color;
  .top-section {
    //background: linear-gradient(136deg, #fff 0%, #ff9090 100%);
    padding: 31px 0;
    ul {
      display: flex;
      justify-content: space-between;
      list-style: none;
      padding-left: 0;
      @include media-breakpoint-down(lg) {
        flex-direction: column;
        gap: 20px;
        position: relative;
        padding:0 15px;
      }
      li {
        a {
          color: $brand-color-white;
          text-align: center;
          //font-family: Montserrat;
          font-size: 18px;
          line-height: 25px;
          font-weight: 400;
          font-family: $font-Roboto-Regular;
          text-decoration: none;
          @include media-breakpoint-down(lg) {
            font-size: 14px;
            line-height: 20px;
          }
          &.footerList-head{
            font-weight: 700;
            font-family: $font-Roboto-Bold;
            @include media-breakpoint-down(lg) {
              font-size: 18px;
              line-height: 20px;
              text-align: left;
            }
          }
        }
        .contact-number {
          display: flex;
          flex-direction: column;
          gap: 20px;
          margin-top: 20px;
          align-items: flex-start;
          @include media-breakpoint-down(lg) {
            align-items: flex-start;
          }
          span {
            font-size: 24px;
            line-height: 30px;
            font-family: $font-Roboto-Regular;
            font-weight: 600;
            color: $brand-text-color;
            @include media-breakpoint-down(lg) {
              font-size: 18px;
              line-height: 20px;
            }
          }
        }
        .social-icons{
          margin:20px 0 0;
          display: flex;
          flex-direction: column;
          gap:15px;
          @include media-breakpoint-down(lg){
            flex-direction: row;
            gap:5px;
            margin:10px 0 0;
          }
          a{
            
            img{
              @include media-breakpoint-down(sm){
                width: 45px;
              }

            }
            &:last-of-type{
              img{
                @include media-breakpoint-down(sm){
                  width: 50px;
                }

            }
          }
          }
        }
        &:nth-child(5) {
          @include media-breakpoint-down(lg) {
            position: absolute;
            right: 18px;
            top:0;
          }
        }
      }
    }
  }
  .bottom-section {
    border-top: 1px solid #d17a7a;
    background-color: $brand-background-color;
    padding: 18px 0;
    width: 100%;
    //display: flex;
    //gap: 43px;
    //max-height: 211px;
    //justify-content: center;
    text-align:center;
    @include media-breakpoint-down(md) {
      // flex-direction: column;
      // align-items: center;
      // gap: 0;
    }
    a {
      font-size: 18px;
      color: $brand-color-white;
      line-height: 25px;
      font-family: $font-Roboto-Regular;
      align-self: center;
      display: block;
      //margin-top:30px;
      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }
    img {
      width: 200px;
      @include media-breakpoint-down(md) {
        width: 150px;
      }
    }
  }
}
