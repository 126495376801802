@import "../../UI/Style/variable.scss";
@import "../../../node_modules/bootstrap/scss/_functions";
@import "../../../node_modules/bootstrap/scss/_variables";
@import "../../../node_modules/bootstrap/scss/mixins";

.hw-t-b-app {
  background-color: $brand-ligh-gray;
  padding: 30px;
  border-radius: 17px;
  h2 {
    font-size: 40px;
    line-height: 45px;
    font-family: $font-Roboto-Bold;
    color: $brand-background-color;
    margin-bottom:20px;
  }
  .book-process {
    display: flex;
    justify-content: space-between;
    .number-plate {
      display: block;
      width: 64px;
      height: 83px;
      padding: 10px;
      border-radius: 11px;
      background-color: $brand-background-color;
      color: $brand-color-white;
      margin-right: 10px;
      font-size: 39px;
      font-family: cursive;
      text-align: center;
      
    }
    .text-plate {
      font-size: 22px;
      line-height: 26px;
      font-family: cursive;
      color: $brand-dark-color;
      align-self: center;
    }
  }
}
