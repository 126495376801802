@import "../../../UI/Style/variable.scss";
@import "../../../../node_modules/bootstrap/scss/_functions";
@import "../../../../node_modules/bootstrap/scss/_variables";
@import "../../../../node_modules/bootstrap/scss/mixins";

.doctorDetail-card {
  padding: 25px 10px;
  box-shadow: 0px 0px 15px 0px rgba(155, 10, 11, 0.3);
  display: flex;
  border-radius: 44px;
  //justify-content: space-between;
  .middle-section {
    display: flex;
    flex-direction: column;
    gap:25px;
    margin-left: 25px;   
    .heading {
      font-size: 33px;
      line-height: 35px;
      color: $brand-dark-color;
      font-weight: 600;
      font-family: $font-Roboto-Bold;
    }
    .subHead{
        font-size: 20px;
        line-height: 25px;
        color: $brand-dark-color;
      font-weight: 600;
      font-family: $font-Roboto-Bold;
    }
    .para-1{
        label,p{
            font-size: 20px;
            line-height: 22px;
            color: $brand-dark-color;
            font-family: $font-Roboto-Medium;

        }
        p{
            font-size: 18px;
            line-height: 20px;
            color: $brand-dark-color;
            font-family: $font-Roboto-Regular;
        }
        
    }
    .para-2{
        label{
            font-size: 18px;
            line-height: 22px;
            color: $brand-dark-color;
            font-family: $font-Roboto-Regular;
        }
        p{
            font-size: 18px;
            line-height: 22px;
            color: $brand-background-color;
            font-family: $font-Roboto-Medium;
        }
    }
  }
  .last-section {
    padding: 0 15px;
    border-left: 2px dashed $brand-dark-color;
    margin-left: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .top-sec {
      .label-text {
        font-size: 19px;
        color: $brand-dark-color;
        font-weight: 600;
        font-family: $font-Roboto-Bold;
        text-align: center;
        margin: 0 0 10px;
      }
      .price-tag {
        justify-content: center;
        margin-bottom: 15px;
        display: flex;
        gap: 15px;
        .old-price {
          font-size: 18px;
          line-height: 20px;
          font-family: $font-Roboto-Medium;
          color: $brand-dark-color;
          position: relative;
          &::before {
            content: "----";
            position: absolute;
            width: 50px;
            color: $brand-background-color;
            left: 7px;
            transform: rotate(-21deg);
            top: -4px;
            font-size: 30px;
          }
        }
        .new-price {
          font-size: 30px;
          line-height: 35px;
          font-family: $font-Roboto-Medium;
          color: $brand-background-color;
        }
      }
    }
    .btn-wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;
      button{
        border-radius: 22px;
        &.secondary-btn{
            background-color: #e2dfdc;
        }
      }
    }
  }
}
