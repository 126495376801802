@import "../Style/variable";
.input-Field{
    border: 1px solid $brand-background-color;
    padding:0 15px;
    width: 100%;
    display: block;
    color: #4E4E4E;
    height: 48px;
    border-radius: 15px;
    outline: none;
    &::placeholder{
        font-style: italic;
        font-family: $font-Roboto-Regular;
        font-size: 14px;
    }
    &.input-error {
        color: $brand-text-color!important;
        font-weight: 600;
        &::placeholder {
          color: $brand-text-color;
          font-weight: 600;
        }
      }
    
}