@import '../../UI/Style/variable.scss';
@import '../../../node_modules/bootstrap/scss/_functions';
@import '../../../node_modules/bootstrap/scss/_variables';
@import '../../../node_modules/bootstrap/scss/mixins';

//@import "../../../node_modules/bootstrap/scss/vendor/rfs.scss";
.bestService {
  //background: linear-gradient($brand-background-gradient-color);
  padding: 130px 0 49px;
  @include media-breakpoint-down(lg) {
    padding: 25px 15px 0;
  }
  .best-Service {
    display: flex;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      padding-bottom: 10px;
      padding: 0 0 10px;
    }

    &.main-service,&.home-care-service {
      .card-section {
        gap: 23px;
        .custom-card{
          p{
            display: none;
          }
        }
      }
    }
    .card-section {
      display: flex;
      gap: 23px;
      flex-wrap: nowrap;
      cursor: pointer;
      padding: 10px 10px;
      @include media-breakpoint-down(md) {
        gap: 20px;
        justify-content: space-between;
      }
      @include media-breakpoint-down(lg) {
        gap: 20px;
        justify-content: space-between;
        flex-wrap: nowrap;
        overflow-x: scroll;
        padding: 10px 5px;
      }
      @media only screen and (min-width: 1300px) and (max-width: 1500px) {
        flex-wrap: nowrap;
        overflow-x: scroll;
        &::-webkit-scrollbar {
          display: none;
      }
      }
      .custom-card {
        min-width: 197px;
        max-width: 220px;
        @include media-breakpoint-down(lg) {
          min-width: 175px;
          max-width: 175px;
        }
      }

      @media only screen and (min-width: 992px) and (max-width: 1279px) {
        overflow-x: scroll;
        overflow-y: hidden;
        padding: 0 15px 0 0;
      }
      .content-wrapper {
        p {
          min-height: 50px;
        }
      }
    }
  }
  .lab-test-query {
    display: flex;
    .path-banner {
      max-width: 100%;
      height: 500px;
      @include media-breakpoint-down(lg) {
        height: initial;
      }
    }
  }
  .query-form {
    background-color: $brand-background-color;
    padding: 27px;
    border-radius: 23px;
    @include media-breakpoint-down(lg) {
      margin: 0;
      max-width: 100%;
      border-radius: 10px;
    }
    .form-heading {
      font-size: 25px;
      line-height: 1.5;
      font-family: $font-Roboto-Medium;
      color: $brand-color-white;
      text-align: center;
      margin-bottom: 20px;
      @include media-breakpoint-down(lg) {
        font-size: 25px;
      }
    }
    .custom-row {
      gap: 24px;
      margin-bottom: 18px;
      @include media-breakpoint-down(lg) {
        gap: 22px;
        margin-bottom: 22px;
      }
    }
    .button-section {
      margin-top: 22px;
      text-align: center;
      button {
        height: 35px;
        border-radius: 9px;
        font-family: $font-Roboto-Medium;
        color: $brand-background-color;
        &:hover {
          color: $brand-color-white;
        }
      }
    }
  }
  .path-at-a-glance {
    margin: 31px auto;
    justify-content: center;
    gap: 50px;
    @include media-breakpoint-down(md) {
      row-gap: 20px;
      column-gap: 67px;
      justify-content: initial;
      padding: 0;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      column-gap: 32px;
      justify-content: center;
      padding: 0;
    }
    .path-item-card {
      background-color: $brand-background-color;
      width: 160px;
      padding: 10px;
      border-radius: 20px;
      @include media-breakpoint-down(lg) {
        width: 120px;
        height: 140px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
      span {
        font-size: 14px;
        line-height: 25px;
        color: $brand-color-white;
        font-family: $font-Roboto-Medium;
        text-align: center;
        @include media-breakpoint-down(lg) {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
  &.pathology {
    .global-heading {
      @include media-breakpoint-up(lg) {
        margin-top: 175px;
      }
    }
    .path-at-a-glance {
      @include media-breakpoint-down(md) {
        column-gap: 13px;
        display: flex;
        flex-wrap: nowrap;
        overflow: scroll;
        //padding: 0 0 0 15px;
      }
      .path-item-card {
        .icon {
          width: 60px;
          @include media-breakpoint-down(lg) {
            width: 65px;
          }
        }
      }
    }
    .custom-row-parent {
      @include media-breakpoint-down(lg) {
        gap: 5px;
      }
    }
  }
  .ambulance-type {
    display: flex;
    justify-content: center;
    gap: 50px;
    @include media-breakpoint-down(md) {
      min-width: 120px;
      flex-wrap: nowrap;
      overflow-x: scroll;
      gap: 15px;
      justify-content: initial;
      padding: 15px 0 15px 5px;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      min-width: 120px;
      gap: 15px;
      justify-content: center;
      padding: 15px 0 15px 5px;
    }
    .ambulance-card {
      max-width: 160px;
      min-width: 160px;
      // max-height: 200px;
      // min-height: 200px;
      box-shadow: 0px 0px 25px 0px rgb(71 71 71 / 30%);
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // gap: 20px;
      padding:10px;
      img{
        width: 100px;
        &.amb-icon{
          width: 80px;
        }
      }
      @include media-breakpoint-down(lg) {
        min-width: 120px;
        box-shadow: 0px 0px 10px 0px rgb(71 71 71 / 30%);
      }
      &:not(.active) {
        cursor: pointer;
      }
      &.active {
        background-color: $brand-background-color;
        .ambulance-code-text {
          span {
            color: $brand-color-white;
            font-size: 20px;
            line-height: 32px;
            font-family: $font-Roboto-Medium;
            @include media-breakpoint-down(lg) {
              font-size: 20px;
            }
          }
        }
      }
      .ambulance-code-text {
        span {
          color: $brand-light-dark-shade;
          font-size: 16px;
          line-height: 20px;
          font-family: $font-Roboto-Regular;
          font-weight: 700;
        }
      }
    }
  }
}
.ambulance-details {
  background-color: $brand-background-color;
  padding: 90px 0;
  margin-bottom: 100px;
  @include media-breakpoint-down(lg) {
    padding: 50px 0;
    text-align: center;
    margin-bottom: 30px;
  }
  .ambulanceSection {
    display: flex;
    gap: 37px;
    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }
    .ambulance-description {
      .heading {
        font-size: 25px;
        line-height: 28px;
        font-family: $font-Roboto-Bold;
        color: $brand-color-white;
        @include media-breakpoint-down(lg) {
          font-size: 18px;
          line-height: 20px;
        }
      }
      .subheading {
        font-size: 18px;
        line-height: 20px;
        font-family: $font-Roboto-Regular;
        color: $brand-color-white;
        margin-top: 20px;
        @include media-breakpoint-down(lg) {
          font-size: 16px;
          line-height: 20px;
        }
      }
      .basic-icons {
        margin-top: 65px;
        display: flex;
        gap: 37px;
        @include media-breakpoint-down(lg) {
          justify-content: center;
          flex-wrap: wrap;
          margin-top: 30px;
          img {
            width: 50px;
            height: 50px;
          }
        }
      }
      .button-section {
        margin-top: 70px;
        button {
          border-radius: 7px;
          height: 37px;
          font-family: $font-Roboto-Medium;
        }
      }
    }
    .ambulance-image{
      min-width: 341px;
    }
  }
}
.bestDoctor {
  margin: 100px 0 40px;
  @include media-breakpoint-down(lg) {
    margin: 0;
  }
  .card-section {
    display: flex;
    gap:23px;
    @media only screen and (min-width: 1300px) and (max-width: 1500px) {
      flex-wrap: nowrap;
      overflow-x: scroll;
      padding: 10px 0;
      &::-webkit-scrollbar {
        display: none;
    }
    }
    @include media-breakpoint-down(lg) {
      flex-wrap: nowrap;
      overflow-x: scroll;
      gap: 35px;
      padding: 0 0 60px;
    }
    .custom-card {
      min-width: 197px;
      max-width: 220px;
      @include media-breakpoint-down(lg) {
        padding: 0;
      }

      img {
        height: 120px;
      }
      .buttonText {
        background-color: $brand-background-color;
        color: $brand-color-white;
        border-radius: 12px;
        padding: 0 5px;
        width: 140px;
        text-align: center;
        align-self: center;
      }
    }
  }
}
.notVis {
  display: none;
}
