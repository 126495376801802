@import './font.scss';

$brand-text-color: #E00000 !default;
$brand-color-white: #fff !default;
$brand-background-gradient-color: 136deg, #FFF 0%, #FF9090 100%;
$brand-background-color:rgb(155, 10, 11)!default;
$brand-light-shade-bg: #FFEBEB!default;
$brand-light-red-bg:#FBE9E9!default;
$brand-disabled-color:#efe8e8;
$brand-dark-color:#000!default;
$brand-light-dark-shade:#474747!default;
$brand-ligh-gray: #f2f2f2!default;
$brand-light-green-bg:#859d4e!default;

/*******Font-Family***************/
$font-Roboto-Regular: "Roboto-Regular";
$font-Roboto-Medium: "Roboto-Medium";
$font-Roboto-Bold: "Roboto-Bold";



$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1900px
);
