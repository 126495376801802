@import "../../../UI/Style/variable.scss";
@import "../../../../node_modules/bootstrap/scss/_functions";
@import "../../../../node_modules/bootstrap/scss/_variables";
@import "../../../../node_modules/bootstrap/scss/mixins";

.doc-con-static-banner {
  display: flex;

  .right-section {
    max-width: 767px;
    img {
      min-height: -webkit-fill-available;
    }
  }
  .left-section {
    background-color: $brand-background-color;
    padding: 20px 20px 20px 100px;
    .banner-Heading {
      font-size: 35px;
      line-height: 42px;
      color: $brand-color-white;
      font-family: $font-Roboto-Bold;
      margin:0;
    }
    .banne-desc {
      font-size: 33px;
      line-height: 40px;
      color: $brand-color-white;
      font-family: $font-Roboto-Medium;
    }
    label {
      background-color: $brand-color-white;
      font-size: 20px;
      line-height: 35px;
      font-family: $font-Roboto-Medium;
      color: $brand-background-color;
      margin: 2rem 0;
      padding: 10px;
      width: 550px;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 50px;
      text-align: center;
      box-shadow: 0px 23px 50px 0px rgba(0, 0, 0, 0.3);
    }
    .feature-list {
      li {
        font-size: 19px;
        line-height: 25px;
        font-family: $font-Roboto-Regular;
        color: $brand-color-white;
        padding: 5px 0;
      }
    }
    .btn-wrapper {
      .custom-button {
        border-radius: 12px;
        display: flex;
        align-items: center;
        svg {
          padding-left: 10px;
        }
      }
    }
  }
}
