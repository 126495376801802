@import "../../UI/Style/variable.scss";
@import "../../../node_modules/bootstrap/scss/_functions";
@import "../../../node_modules/bootstrap/scss/_variables";
@import "../../../node_modules/bootstrap/scss/mixins";

.quick-booking {
  background-image: url("../../assets/Images/Global/background-image.jpg");
  padding:40px;
  margin:100px 0;
  .heading {
    font-size: 33px;
    line-height: 38px;
    color: $brand-color-white;
    font-family: $font-Roboto-Bold;
    
  }
  .things-to-remember{
    margin-top:15px;
    ul{
       
        li{
            &::marker{
                color:$brand-color-white;
            }
            p,label{
                color:$brand-color-white;
                font-size: 19px;
                line-height: 22px;
                font-family: $font-Roboto-Regular;
            }
            label{
                font-weight: 500;
            }
            
        }
    }
    

  }
  .custom-col{
    align-self: center;
  }
  .form-section{
    background-color: $brand-color-white;
    padding: 30px;
    border-radius: 16px;
    text-align: center;
    .heading-section{
        text-align: center;
        margin:0 0 10px;
        .heading{
            font-size: 25px;
            line-height: 28px;
            font-family: $font-Roboto-Bold;
            color:$brand-dark-color;
        }
        .subheading{
            font-size: 18px;
            line-height: 24px;
            font-family: $font-Roboto-Medium;
            color:$brand-dark-color;
        }
    }
    input{
        
        &.input-Field{
            margin: 10px 0;
            background: $brand-ligh-gray;
        }
    }
    .custom-button{
        border-radius: 31px;
    }
}
}
