body{
  overflow-x: hidden;
}
header {
  position: fixed;
  z-index: 1;
  background-color: #fff;
  width: 100%;
}
section {
  position: absolute;
  z-index: -1;
  top: 110px;
  width: 100%;
  /* @media only screen and (max-width:991px){
    top: 100px;
  } */
}
.slick-dots{
  bottom: -39px!important;
}
.slick-dots .slick-active button::before{
    font-size: 15px;
    color:#AF0000!important;
}
.slick-dots li button::before{
  font-size: 15px!important;
  color:#E00000!important;
}
