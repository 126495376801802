@import '../UI/Style/variable.scss';
@import '../../node_modules/bootstrap/scss/_functions';
@import '../../node_modules/bootstrap/scss/_variables';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules//bootstrap/scss/modal';

.container-fluid {
  @include media-breakpoint-up(xl) {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
}
.offer-tab {
  height: 40px;
  background-color: $brand-background-color;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  span,
  a {
    font-size: 16px;
    line-height: 20px;
    font-family: $font-Roboto-Regular;
    color: $brand-color-white;
    @include media-breakpoint-down(lg) {
      font-size: 8px;
      line-height: 20px;
    }
  }
  .right-section {
    display: flex;
    gap: 10px;
    @include media-breakpoint-down(lg){
      gap:5px;
    }
    a{
      text-decoration: none;
    }
  }
}
.custom-navbar {
  max-height: 70px;
  box-shadow: 0px 23px 50px 0px rgba(0, 0, 0, 0.3);
  .main-header {
    @include media-breakpoint-down(lg) {
      top: -9px;
      position: relative;
    }
  }
  .container-fluid {
    @include media-breakpoint-down(lg) {
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    .navbar-brand {
      @include media-breakpoint-down(sm) {
        img {
          width: 100px;
        }
      }
    }

    .navbar-toggler {
      svg {
        font-size: 40px;
        @include media-breakpoint-down(lg) {
          font-size: 30px;
        }
      }
      &[aria-expanded='false'] {
        [data-icon='window-close'] {
          display: none;
        }
        [data-icon='bars'] {
          display: block;
        }
      }

      &:focus {
        border: none;
        outline: none;
        box-shadow: none;
      }
      &[aria-expanded='true'] {
        position: relative;
        //top: -10px;
        [data-icon='bars'] {
          display: none;
        }
        [data-icon='window-close'] {
          display: block;
        }
        // background-image: none;
        // &::before {
        //   content: url('../assets/Images/Svg/xmark-solid.svg');
        // }
      }
      @include media-breakpoint-down(sm) {
        border: none;
      }
    }
  }
  .navbar-collapse {
    justify-content: flex-end;

    .navbar-nav {
      .nav-item {
        &:last-of-type {
          @include media-breakpoint-up(lg) {
            align-self: center;
          }
        }
        @include media-breakpoint-down(sm) {
          padding: 0 !important;
        }
      }
    }

    @include media-breakpoint-down(lg) {
      background-color: $brand-color-white;
      margin: -7px -15px;
      height: 100vh;
      overflow-y: scroll;
      border-top: 1px solid $brand-text-color;

      .navbar-nav {
        margin: 0 15px !important;
        gap: 0 !important;
        .nav-item {
          border-bottom: 1px solid $brand-text-color;
          padding: 15px;
        }
      }
    }
    .navbar-nav {
      gap: 42px;
      margin: 0 0 0 55px;
      @media only screen and (min-width: 992px) and (max-width: 1279px) {
        gap: 10px;
        margin: 0 0 0 10px;
      }
      .nav-item {
        .nav-link {
          color: $brand-text-color;
          //font-family: Montserrat;
          font-size: 18px;
          font-weight: 500;
          line-height: 24px;
          font-family: $font-Roboto-Regular;
          letter-spacing: 0em;
          text-align: left;
          padding: 15px;
          transition: 0.2s;
          @media only screen and (min-width: 992px) and (max-width: 1279px) {
            font-size: 16px;
            line-height: 18px;
          }
          &.active {
            font-weight: 700;
            border-bottom: 2px solid;
            @include media-breakpoint-down(sm) {
              border: none;
            }
          }
          &:hover {
            background-color: $brand-light-shade-bg;
            @include media-breakpoint-down(sm) {
              background-color: transparent;
            }
          }
          &.dropdown-toggle {
            &.show {
              @include media-breakpoint-up(lg) {
                background: #fff;
                box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.2);
                transition: 0.2s;
              }
              @include media-breakpoint-down(sm) {
                background-color: $brand-light-shade-bg;
              }
            }
          }
        }
        .dropdown-menu {
          transition: 0.5s;
          &.show {
            @include media-breakpoint-down(lg) {
              border: 0;
              margin: 0 !important;
              height: 180px;
              overflow-y: scroll;
              li {
                border-bottom: 1px solid $brand-text-color;
                padding: 15px;
                a {
                  color: $brand-text-color;
                  font-size: 18px;
                  line-height: 20px;
                  font-family: $font-Roboto-Regular;
                }
              }
              &:nth-child(5) {
                border-bottom: none;
              }
            }
            @include media-breakpoint-up(lg) {
              margin: 0;
              border-radius: 0;
              border: 0;
              box-shadow: 0px 0px 0 0px rgba(0, 0, 0, 0.7);
              li {
                padding: 15px;
                border-bottom: 1px dashed $brand-text-color;
                a {
                  font-size: 18px;
                  line-height: 24px;
                  color: $brand-text-color;
                  font-weight: 500;
                  font-family: $font-Roboto-Regular;
                  &:hover {
                    background-color: $brand-light-shade-bg;
                  }
                }
                &:last-of-type {
                  border-bottom: 0;
                }
              }
            }
            @include media-breakpoint-down(sm) {
              li {
                border-bottom: 1px dashed $brand-text-color;
              }
            }
          }
        }
        button {
          width: 140px;
          height: 43px;
          border-radius: 10px;
        }
      }
    }

    .signin-reg-Sec {
      max-height: 51px;
      display: flex;
      @include media-breakpoint-down(sm) {
        justify-content: center;
        max-height: 35px;
      }
      a {
        color: $brand-text-color;
        //font-family: Montserrat;
        font-size: 24px;
        line-height: 30px;
        font-family: $font-Roboto-Regular;
        font-weight: 700;
        text-decoration: none;
        transition: 1s;
        padding: 15px;
        cursor: pointer;
        align-self: center;
        @media only screen and (min-width: 992px) and (max-width: 1279px) {
          font-size: 16px;
          line-height: 18px;
        }
        @include media-breakpoint-down(sm) {
          font-size: 18px;
          line-height: 24px;
        }
        &:hover {
          background-color: $brand-light-shade-bg;
        }
        &:first-of-type {
          margin: 0 14px 0 0;
        }
        &:last-of-type {
          margin-left: 14px;
        }
      }

      @include media-breakpoint-down(lg) {
        text-align: center;
        position: relative;
        bottom: -56px;
      }
      span {
        border-right: 2px solid $brand-text-color;
        height: auto;
      }
    }
  }
}
