@import "../../UI/Style/variable.scss";
@import "../../../node_modules/bootstrap/scss/_functions";
@import "../../../node_modules/bootstrap/scss/_variables";
@import "../../../node_modules/bootstrap/scss/mixins";
.offerSubscription-section {
  background-color: $brand-background-color;
  padding: 72px 0 76px;
  @include media-breakpoint-down(lg){
    padding: 25px 0 76px;
  }
  .heading {
    h2 {
      color: $brand-color-white;
      //font-family: Roboto;
      font-size: 32px;
      line-height: 40px;
      margin: 0 0 23px;
      text-align: center;
      @include media-breakpoint-down(lg){
        font-size: 25px;
      }
    }
  }
  .custom-row {
    position: relative;
    left: 70px;
    @include media-breakpoint-down(lg) {
     left:initial;
    }
    .button-section {
      position: absolute;
      right: 247px;
      top: 4px;
      @include media-breakpoint-down(lg) {
        right: 28px;
       }
      .primary-btn{
        border-radius: 15px;
        height: 40px;
      }
    }
  }
  span {
    font-size: 20px;
    line-height: 25px;
    color: $brand-color-white;
    &.notvis {
      display: none;
    }
  }
}
