@import '../../UI/Style/variable.scss';
@import '../../../node_modules/bootstrap/scss/_functions';
@import '../../../node_modules/bootstrap/scss/_variables';
@import '../../../node_modules/bootstrap/scss/mixins';
.homecare-banner {
  position: relative;
  @include media-breakpoint-down(lg){
    margin:30px 0 30px 0;
  }
  .banner-img {
    height: 470px;

    @include media-breakpoint-down(lg) {
      display: none;
    }
    &-mob {
      @include media-breakpoint-down(lg) {
        display: block;
      }
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }
  .banner-content {
    @include media-breakpoint-up(lg) {
      position: absolute;
      right: 5%;
      top: 130px;
      max-width: 53%;
    }
    @include media-breakpoint-down(lg) {
      background-color: $brand-light-green-bg;
      padding: 15px;
      max-width: 100%;
    }

    .heading {
      @include media-breakpoint-down(lg){
        text-align: center;
      }
      span {
        font-family: $font-Roboto-Bold;
        font-size: 50px;
        line-height: 1.2;
        color: $brand-color-white;
        @include media-breakpoint-down(lg){
          font-size: 25px;
        }
      }
    }
    .buttonSec {
      text-align: center;
      margin-top: 55px;
      button {
        border-radius: 7px;
        height: 36px;
        font-family: $font-Roboto-Medium;
      }
    }
  }
}
