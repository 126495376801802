@import "./variable.scss";
@import "../../../node_modules/bootstrap/scss/_functions";
@import "../../../node_modules/bootstrap/scss/_variables";
@import "../../../node_modules/bootstrap/scss/mixins";
.global-heading {
  margin: 0 0 65px 0;
  @include media-breakpoint-down(lg){
    margin: 0 0 25px 0;
    text-align: center;
  }
  h2 {
    font-size: 33px;
    line-height: 25px;
    font-family: $font-Roboto-Medium;
    font-weight: 700;
    color: $brand-background-color;
    @include media-breakpoint-up(md){
      font-size: 27px;
      line-height: 30px;
  }
  @include media-breakpoint-down(lg){
    font-size: 18px;
      line-height: 25px;
      
  }
  }
  span {
    font-size: 20px;
    line-height: 28px;
    font-family: $font-Roboto-Regular;
    margin-top: 10px;
    @include media-breakpoint-up(md){
      font-size: 18px;
      line-height: 28px;
  }
  @include media-breakpoint-down(lg){
    font-size: 14px;
      line-height: 20px;
  }
  }
  .text-position{
    text-align: end;
    @include media-breakpoint-down(lg){
      text-align: center;
      margin-top:25px;
    }
  }
  button{
    border-radius: 5px;
    padding:0 10px;
    
  }
}
