@import "../../UI/Style/variable.scss";
@import "../../../node_modules/bootstrap/scss/_functions";
@import "../../../node_modules/bootstrap/scss/_variables";
@import "../../../node_modules/bootstrap/scss/mixins";

.small-banner {
  position: relative;
  .img-wrapper {
    box-shadow: 0px 0px 15px 0px rgba(155, 10, 11, 0.3);
    border-radius: 22px;
  }
  .content-wrapper {
    position: absolute;
    top: 50px;
    left: 25px;
    right: 25px;
    .para {
      .head-text {
        margin-bottom: 20px;
        span {
          font-size: 16px;
          line-height: 20px;
          font-family: $font-Roboto-Bold;
          color: $brand-dark-color;
        }
      }
      label {
        font-size: 16px;
        line-height: 20px;
        font-family: $font-Roboto-Medium;
        color: $brand-dark-color;
        margin-bottom: 20px;
      }
      ul {
        li {
          font-size: 16px;
          line-height: 20px;
          font-family: $font-Roboto-Medium;
          color: $brand-dark-color;
          margin-bottom: 15px;
        }
      }
      
    }
    .btn-wrapper {
        margin-top:20px;
        .primary-btn {
          border-radius: 22px;
          
        }
      }
  }
}
