@import "../../UI/Style/variable.scss";
@import "../../../node_modules/bootstrap/scss/_functions";
@import "../../../node_modules/bootstrap/scss/_variables";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../UI/Style/global.scss";
.top-speciality {
  margin: 60px 0;
  @include media-breakpoint-down(lg){
    margin:25px 0;
  }
  .service-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 35px;
    column-gap: 0px;
    @include media-breakpoint-down(lg){
      column-gap: 0px;
      row-gap: 25px;
      justify-content: space-between;
  }
    .features {
      display: flex;
      flex-direction: column;
      min-width: 165px;
      @include media-breakpoint-down(lg){
        min-width: 100px;
      }
      .facility-image {
        width: 100px;
        height: 100px;
        background-color: $brand-background-color;
        border-radius: 15px;
        align-self: center;
        @include media-breakpoint-down(lg){
          width: 60px;
          height: 60px;
          border-radius: 10px;
          img{
            width: 60px;
          }
        }
      }
      .facility-desc,
      a {
        align-self: center;
        font-family: $font-Roboto-Regular;
      }
      .facility-desc {
        font-size: 18px;
        line-height: 18px;
        margin: 13px;
        @include media-breakpoint-down(lg){
          font-size: 14px;
          margin: 5px;
        }
      }
      a {
        font-size: 20px;
        line-height: 20px;
        color: $brand-background-color;
        text-decoration: none;
        cursor: pointer;
        //margin-top: 15px;
        font-weight: 700;
        @include media-breakpoint-down(lg){
          font-size: 16px;
          //margin-top: 10px;
          font-weight: 600;
        }
        
      }
    }
  }
}
