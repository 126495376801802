@import "../../UI/Style/variable.scss";
@import "../../../node_modules/bootstrap/scss/_functions";
@import "../../../node_modules/bootstrap/scss/_variables";
@import "../../../node_modules/bootstrap/scss/mixins";

.global-call-assistance {
  background-color: $brand-ligh-gray;
  border-radius: 17px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-top: 40px;
  .text-wrapper{
    h2{
        font-size: 27px;
        line-height: 32px;
        color:$brand-background-color;
        font-family: $font-Roboto-Medium;
    }
    span{
        font-size: 20px;
        line-height: 25px;
        color:$brand-dark-color;
        font-family: $font-Roboto-Regular;
        font-weight: bold;
    }
  }
  .button-wrapper{
    align-self: center;
    .primary-btn{
        border-radius: 34px;
        

    }
  }
}
