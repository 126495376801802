@import "../../UI/Style/variable.scss";
@import "../../../node_modules/bootstrap/scss/_functions";
@import "../../../node_modules/bootstrap/scss/_variables";
@import "../../../node_modules/bootstrap/scss/mixins";

.homePage-bodyContent{
    position: relative;
    top:20px;
    @include media-breakpoint-up(xxl){
        top:350px;
    }
    // @include media-breakpoint-down(md){
    //     top:312px;
    // }
   
    @media only screen and (min-device-width: 800px) and (max-device-width: 1199px){
        top:450px;
    }
    @media only screen and (min-device-width: 768px) and (max-device-width: 799px){
        top:312px;
    }
    @include media-breakpoint-down(sm){
        top:118px;
    }
}