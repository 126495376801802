@import '../../UI/Style/variable.scss';
@import '../../../node_modules/bootstrap/scss/_functions';
@import '../../../node_modules/bootstrap/scss/_variables';
@import '../../../node_modules/bootstrap/scss/mixins';
.static-banner {
  position: relative;
  //margin-bottom: 100px;;
  .banner-img {
    height: 500px;

    @include media-breakpoint-down(lg) {
      display: none;
    }
    &-mob {
      @include media-breakpoint-down(lg) {
        display: block;
      }
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
  }
  .banner-content {
    @include media-breakpoint-up(lg) {
      position: absolute;
      //transform: translate(-50%, 0%);
      right: 5%;
      top: 35px;
      max-width: 50%;
    }
    @include media-breakpoint-down(lg) {
      background-color: $brand-background-color;
      padding: 15px;
      text-align: center;
      .col-6 {
        padding: 0;
      }
    }
    .heading {
      text-align: center;
      span {
        font-size: 25px;
        line-height: 40px;
        font-family: $font-Roboto-Regular;
        color: $brand-color-white;
        font-weight: 600;
        @include media-breakpoint-down(lg) {
          font-size: 20px;
          line-height: 35px;
        }
      }
    }
    .subHeading {
      margin: 20px 0 20px;
      text-align: center;
      span {
        font-size: 16px;
        line-height: 20px;
        font-family: $font-Roboto-Regular;
        color: $brand-color-white;
      }
    }
    .services-section {
      span {
        font-size: 18px;
        line-height: 25px;
        font-family: $font-Roboto-Regular;
        color: $brand-color-white;
        align-self: center;
        padding-left: 10px;
        @include media-breakpoint-down(lg) {
          font-size: 16px;
          line-height: 20px;
        }
        &.icon-container{
          text-align: center;
        }
        &.service-text{
          text-align: left;
        }
      }
      .custom-row {
        margin-bottom: 20px;
      }
      .icon{
        max-width: 70%;
      }
    }
    .btn-container {
      text-align: center;
      margin-top: 30px;
      button {
        height: 36px;
        border-radius: 8px;
        width: 190px;
      }
    }
  }
}
