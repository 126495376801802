@import '../../UI/Style/variable.scss';
@import '../../../node_modules/bootstrap/scss/_functions';
@import '../../../node_modules/bootstrap/scss/_variables';
@import '../../../node_modules/bootstrap/scss/mixins';
@import '../../../node_modules//bootstrap/scss/modal';
.about-page {
  position: relative;
  height: 445px;
  @media only screen and  (max-device-width: 766px){
    top:80px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 799px){
    top:380px;
  }
  @media only screen and (min-device-width: 800px) and (max-device-width: 1199px){
    top:450px;
}
  &_intro,&_mission-vision {
    padding: 100px 0 50px;
  }
  &_heading {
    margin-top: 25px;
    span {
      font-size: 25px;
      line-height: 20px;
      font-family: $font-Roboto-Bold;
      color: $brand-background-color;
      @include media-breakpoint-down(lg){
        font-size: 18px;
      }
    }
  }
  &_introduction {
    margin-top: 25px;
    p {
      font-size: 14px;
      line-height: 20px;
      font-family: $font-Roboto-Regular;
      color: $brand-dark-color;
      @include media-breakpoint-down(lg){
        font-size: 14px;
      }
    }
  }
  &_subheading {
    margin-top: 25px;
    span {
      font-size: 18px;
      line-height: 20px;
      font-family: $font-Roboto-Bold;
      color: $brand-background-color;
      @include media-breakpoint-down(lg){
        font-size: 16px;
      }
    }
  }
  &_label {
    span {
      font-size: 18px;
      line-height: 20px;
      font-family: $font-Roboto-Bold;
      color: $brand-background-color;
      @include media-breakpoint-down(lg){
        font-size: 16px;
      }
    }
  }
  &_intro-para {
    margin-top: 25px;
    p {
      font-size: 14px;
      line-height: 20px;
      font-family: $font-Roboto-Regular;
      color: $brand-dark-color;
      @include media-breakpoint-down(lg){
        font-size: 14px;
      }
    }
  }
  &_services {
    ul {
      li {
        font-size: 14px;
        line-height: 20px;
        font-family: $font-Roboto-Regular;
        margin: 10px;
        color: $brand-dark-color;
        @include media-breakpoint-down(lg){
          font-size: 14px;
        }
      }
    }
  }
  &_director-desk{
    padding: 35px 0 50px;
    &_director-image{
      text-align: end;
      @include media-breakpoint-down(lg){
        text-align: center;
      }
      &_image{
         @include media-breakpoint-down(lg){
          max-width: 100%;
        }
      }
    }
  }
  &_director-intro{
    margin-top: 25px;
    p {
      font-size: 14px;
      line-height: 20px;
      font-family: $font-Roboto-Bold;
      color: $brand-dark-color;
      .about-page_director-name {
        color: $brand-background-color;
      }
    }
  }
  &_mission-vision{
    .custom-row{
      row-gap: 15px;
    }
  }
  .about-image-group{
    align-self: center;
  }
}
.display-lg {
  // @include media-breakpoint-down(md){
  //   display: none!important;

  // }
  // @include media-breakpoint-down(sm){
  //   display: none!important;

  // }
  @media only screen and (max-width: 1100px) {
    display: none !important;
  }
}
.display-md {
  // @include media-breakpoint-up(md){
  //   display: none!important;

  // }
  // @include media-breakpoint-up(sm){
  //   display: none!important;

  // }
  @media only screen and (min-width: 1200px) {
    display: none !important;
  }
}