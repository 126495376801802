@import "../../UI/Style/variable.scss";
@import "../../../node_modules/bootstrap/scss/_functions";
@import "../../../node_modules/bootstrap/scss/_variables";
@import "../../../node_modules/bootstrap/scss/mixins";
.membership-card {
  position: relative;
  .banner-image {
    .patch {
      position: absolute;
      background-color: $brand-background-color;
      color: $brand-color-white;
      box-shadow: 0px 9px 50px 0px rgba(0, 0, 0, 0.3);
      top: 0;
      width: 120px;
      height: 44px;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      left: 100px;

      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-size: 25px;
        line-height: 25px;
        font-family: $font-Roboto-Regular;
      }
    }
  }
  .banner-content {
    position: absolute;
    top: 15%;
    width: 65%;
    .price-tag {
      margin-bottom: 15px;
      display: flex;
      gap: 15px;
      .old-price {
        font-size: 18px;
        line-height: 20px;
        font-family: $font-Roboto-Medium;
        color: $brand-dark-color;
        position: relative;
        &::before {
          content: "----";
          position: absolute;
          width: 50px;
          color: $brand-background-color;
          left: 7px;
          transform: rotate(-21deg);
          top: -4px;
          font-size: 30px;
        }
      }
      .new-price {
        font-size: 30px;
        line-height: 35px;
        font-family: $font-Roboto-Medium;
        color: $brand-background-color;
      }
    }
    .banner-heading {
      font-size: 32px;
      line-height: 35px;
      font-family: $font-Roboto-Bold;
      color: $brand-background-color;
      margin-bottom: 15px;
      font-weight: 700;
    }
    .subheading {
      font-size: 25px;
      line-height: 30px;
      font-family: $font-Roboto-Medium;
      color: $brand-dark-color;
      margin-bottom: 5px;
    }
    .card-benifits {
      p {
        font-size: 16px;
        line-height: 22px;
        font-family: $font-Roboto-Regular;
        color: $brand-dark-color;
        font-weight: 600;
      }
    }
    .btn-wrapper {
      .primary-btn {
        svg {
          margin-left: 5px;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
      }
    }
  }
}
