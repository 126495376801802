@import "../../UI/Style/variable.scss";
@import "../../../node_modules/bootstrap/scss/_functions";
@import "../../../node_modules/bootstrap/scss/_variables";
@import "../../../node_modules/bootstrap/scss/mixins";

.doctor-speciality-comp {
  padding: 130px 0 100px;
  .doctor-speciality-pool {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 48px;
    .custom-card {
      min-width: 225px;
      max-width: 225px;
      min-height: 245px;
      max-height: 245px;
      cursor: pointer;
      .content-wrapper {
        .heading {
          text-align: center;
        }
      }
    }
  }
  
}
.doctor-detail{
  margin: 100px auto 100px;
  h2{
    font-size: 33px;
    font-family: $font-Roboto-Bold;
    line-height: 38px;
    color:$brand-background-color;
    margin-bottom:50px;
  }
  .body-section{
    display: flex;
    gap: 25px;
    .card-stack{
      display: flex;
      flex-direction: column;
      gap: 25px;
    }

  }
}
