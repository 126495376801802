@import '../../UI/Style/variable.scss';
@import '../../../node_modules/bootstrap/scss/_functions';
@import '../../../node_modules/bootstrap/scss/_variables';
@import '../../../node_modules/bootstrap/scss/mixins';
.custom-button {
  min-width: 138px;
  height: 48px;
  color: $brand-text-color;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  font-family: $font-Roboto-Regular;
  background-color: $brand-color-white;
  border: none;
  outline: none;
  @include media-breakpoint-up(md) {
    font-size: 16px;
    line-height: 24px;
    
  }
  @include media-breakpoint-down(lg) {
    font-size: 16px;
    line-height: 25px;
    min-width: 120px;
    height: 40px;
  }
  &.primary-btn {
    background-color: $brand-background-color;
    color: $brand-color-white;
    border-radius: 10px;
  }
  @include media-breakpoint-down(md) {
  }
  &:hover {
    background-color: $brand-text-color;
    color: $brand-color-white;
  }
}
