@import '../../UI/Style/variable.scss';
@import '../../../node_modules/bootstrap/scss/_functions';
@import '../../../node_modules/bootstrap/scss/_variables';
@import '../../../node_modules/bootstrap/scss/mixins';
@import '../../../node_modules//bootstrap/scss/modal';

.graphics-banner {
  background-color: $brand-light-red-bg;
  text-align: center;
  padding: 0 0 45px;
  @include media-breakpoint-down(md) {
    padding: 0 0 25px;
  }

  .heading {
    padding: 45px 0 20px;
    @include media-breakpoint-down(sm) {
      padding: 35px 0 25px;
    }
    span {
      font-size: 40px;
      line-height: 30px;
      color: $brand-text-color;
      font-weight: 700;
      font-family: $font-Roboto-Regular;
      @include media-breakpoint-down(sm) {
        font-size: 28px;
      }
    }
  }
  img {
    max-width: 100%;
    width: 100%;
    @include media-breakpoint-up(lg) {
      width: 250px;
      height: 250px;
    }
    &.desktop-img {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
    &.mob-img {
      @include media-breakpoint-up(lg) {
        display: none;
        width: 250px;
        height: 250px;
      }
    }
  }
}
.homepage-banner {
  position: relative;
  height: 445px;
  @include media-breakpoint-down(md) {
    // max-width: 767px;
    height: 280px;
  }
  .slick-prev {
    left: 10px;
    z-index: 3;

    &::before {
      color: $brand-background-color;
      @include media-breakpoint-up(lg) {
        font-size: 45px;
      }
    }
  }
  .slick-next {
    right: 35px;
    z-index: 3;
    @include media-breakpoint-down(lg){
      right: 14px;
    }
    &::before {
      color: $brand-background-color;
      @include media-breakpoint-up(lg) {
        font-size: 45px;
      }
    }
  }
  .banner-content {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 32%;
    transform: translateX(-50%);
    animation-name: slideInFromLeft;
    animation-duration: 4s;
    max-width: 50%;
    @include media-breakpoint-down(lg) {
      left: 28%;
      top: 20%;
      animation-name: slideInFromLeftTab;
    }
    @include media-breakpoint-down(md) {
      //max-width: 767px;
      top: 10%;
      left: 46%;
      max-width: 80%;
      animation-name: slideInFromLeftMob;
      animation-duration: 1s;
      width: 100%;
    }
    &.doctor-banner {
      left: 23%;

      @include media-breakpoint-down(md) {
        left: 46%;
      }
    }
    h1 {
      font-size: 35px;
      line-height: 30px;
      color: $brand-background-color;
      font-family: $font-Roboto-Regular;
      font-weight: 700;
      @include media-breakpoint-down(lg) {
        font-size: 18px;
        line-height: 22px;
      }
    }
    .subheading {
      margin-top: 15px;
      @include media-breakpoint-down(lg) {
        margin-top: 5px;
      }
      span {
        font-size: 18px;
        line-height: 25px;
        color: $brand-text-color;
        font-family: $font-Roboto-Regular;
        font-weight: 500;
        @include media-breakpoint-down(lg) {
          font-size: 14px;
          line-height: 16px;
          font-family: $font-Roboto-Bold;
        }
      }
    }
  }
}
.display-lg {
  // @include media-breakpoint-down(md){
  //   display: none!important;

  // }
  // @include media-breakpoint-down(sm){
  //   display: none!important;

  // }
  @media only screen and (max-width: 1100px) {
    display: none !important;
  }
}
.display-md {
  // @include media-breakpoint-up(md){
  //   display: none!important;

  // }
  // @include media-breakpoint-up(sm){
  //   display: none!important;

  // }
  @media only screen and (min-width: 1200px) {
    display: none !important;
  }
}
@keyframes slideInFromLeft {
  0% {
    top: 0;
  }

  100% {
    top: 50%;
  }
}
@keyframes slideInFromLeftMob {
  0% {
    top: 0;
  }

  100% {
    top: 10%;
  }
}
@keyframes slideInFromLeftTab {
  0% {
    top: 0;
  }

  100% {
    top: 20%;
  }
}
