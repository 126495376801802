/* File: font.css */
@font-face {
    font-family: 'Roboto-Regular';
    src: local('Roboto-Regular'),
         url('../../assets/Fonts/Roboto-Regular.woff') format('woff');
         
}
@font-face {
    font-family: 'Roboto-Medium';
    src: local('Roboto-Medium'),
         url('../../assets/Fonts/Roboto-Medium.woff') format('woff');
         
}

@font-face {
    font-family: 'Roboto-Bold';
    src: local('Roboto-Bold'),
         url('../../assets/Fonts/Roboto-Bold.woff') format('woff');
         
}
